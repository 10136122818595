import { ServiceFactory } from '@/services/ServiceFactory'
// const _videosService = ServiceFactory.get('BarVideoService')
const _appControlService = ServiceFactory.get('AppControlService')

export default {
  data () {
    return {
      loading: false,
      dialog: false,
      postData: {
        category: '',
        tiktokVideoUrl: '',
        order: '',
        thumbnailFile: null,
        qViews: '',
        qLikes: '',
        qComments: '',
        qShares: '',
        zbBarId: '',
        businessTitle: ''
      },
      categories: ['Videos populares', 'Videos nuevos'],
      showError: false,
      // imageBanner: null,
      items: [ 1, 2, 3 ],
      thumbnail: {
        image: null
      }
      // inputFile: null
    }
  },
  props: {
    itemData: Object,
    action: String,
    // zbBarId: String,
    businessList: Array,
    category: String,
    videoOrder: Array
    // modal: Boolean
  },
  watch: {
    // itemData: {
    //   handler: 'setItem',
    //   immediate: true
    // },
    // category: {
    //   handler: 'setItem',
    //   immediate: true
    // },
    // action: {
    //   handler: 'initAction',
    //   immediate: true
    // },
    // zbBarId: {
    //   handler: 'initAction',
    //   immediate: true
    // },
    videoOrder: {
      handler: 'initAction',
      immediate: true
    }
  },
  methods: {
    async initAction () {
      console.log(this.businessList)
      console.log(this.itemData)
      console.log(this.videoOrder)
      console.log(this.items)
      if (this.action === 'create') {
        this.postData.order = this.videoOrder[this.videoOrder.length - 1]
      } else {
        this.postData.order = ''
        this.postData.tiktokVideoUrl = this.itemData.author_url + '/' + this.itemData.channel + '/' + this.itemData.embed_product_id
        this.thumbnail.image = this.itemData.thumbnail_url
        this.postData.qViews = this.itemData.q_views
        this.postData.qLikes = this.itemData.q_likes
        this.postData.qComments = this.itemData.q_comments
        this.postData.qShares = this.itemData.q_shares
        this.postData.zbBarId = this.itemData.zbBarId
        this.postData.businessTitle = this.itemData.businessTitle
      }
      // this.items = this.videoOrder
      // if (this.modal) {
      //   this.dialog = true
      // }
    },
    clearThumbnail () {
      // alert('clear thumnail')
      this.thumbnail.image = null
      this.postData.thumbnailFile = null
      document.getElementById('thumbFile').value = ''
    },
    async setItem () {
      console.log(this.itemData)
      console.log(this.action)
      console.log(this.category)
      console.log(this.videoOrder)
      console.log(this.items)
      // if (this.itemData) {
      //   this.postData.tiktokVideoUrl = this.itemData.video.tiktokVideoUrl
      //   this.postData.order = this.itemData.video.order
      //   this.thumbnail.image = this.itemData.video.meta.thumbnail_url
      // }
      // this.postData.category = this.category
    },
    viewData () {
      console.log(this.itemData)
      console.log(this.action)
      console.log(this.category)
      console.log(this.videoOrder)
    },
    closeModal () {
      // this.imgBanner = {
      //   image: ''
      // }
      // this.image = {
      //   width: '',
      //   height: ''
      // }
      this.postData = {
        category: '',
        tiktokVideoUrl: '',
        order: '',
        thumbnailFile: null,
        qViews: '',
        qLikes: '',
        qComments: '',
        qShares: '',
        zbBarId: ''
      }
      this.thumbnail.image = null
      console.log('closeeeeee')
      this.loading = false
      this.dialog = false
      this.$emit('closeThisModal')
    },
    clearForm () {
      this.postData = {
        category: '',
        tiktokVideoUrl: '',
        order: '',
        thumbnailFile: null,
        qViews: '',
        qLikes: '',
        qComments: '',
        qShares: '',
        zbBarId: ''
      }
      this.thumbnail.image = null
      document.getElementById('thumbFile').value = ''
    },
    // deleteImg () {
    //   this.loading = true
    //   this.$swal({
    //     title: '¿Está seguro de eliminar la imágen?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sí, eliminar',
    //     cancelButtonText: 'Cancelar'
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       try {
    //         console.log(this.zbBarId, this.imgBanner.image)
    //         // const pd = {
    //         //   image: this.imgBanner.image
    //         // }
    //         // await _barsService.delCarImg(this.zbBarId, this.imgBanner)
    //         this.$swal(
    //           'Imágen eliminada',
    //           'Se eliminó correctamente',
    //           'success'
    //         ).then(async (result) => {
    //           // this.initialize()
    //           this.loading = false
    //           this.$emit('closeThisModal')
    //         })
    //       } catch (error) {
    //         this.loading = false
    //         console.log(error)
    //         this.$notify({
    //           title: 'Error:',
    //           type: 'error',
    //           text: 'La imágen no se pudo eliminar.'
    //         })
    //       }
    //     }
    //   })
    // },
    async submit () {
      if (this.thumbnail.image) {
        this.loading = true
        console.log(this.postData)
        this.postData.category = this.category
        // console.log(this.imageBanner)
        if (this.action === 'create') {
          try {
            // this.postData.order = parseInt(this.postData.order)
            // this.postData.zbBarId = this.zbBarId
            await _appControlService.create(this.postData)
            this.$notify({
              type: 'success',
              text: 'Video agredado correctamente'
            })
            this.loading = false
            this.clearForm()
            this.$emit('closeThisModal')
            // this.$emit('closeModal')
          } catch (error) {
            console.log(error)
            this.loading = false
            this.$notify({
              type: 'error',
              text: 'Ocurrió un error al guardar el video'
            })
          }
        } else {
          try {
            console.log(this.itemData.id)
            console.log(this.postData)
            this.postData.qViews = parseInt(this.postData.qViews)
            this.postData.qLikes = parseInt(this.postData.qLikes)
            this.postData.qComments = parseInt(this.postData.qComments)
            this.postData.qShares = parseInt(this.postData.qShares)
            var resp = await _appControlService.edit(this.itemData.id, this.postData)
            console.log(resp)
            this.$notify({
              type: 'success',
              text: 'Video modificado correctamente'
            })
            this.loading = false
            this.clearForm()
            this.$emit('closeThisModal')
            // this.$emit('closeModal')
          } catch (error) {
            console.log(error)
            this.loading = false
            this.$notify({
              type: 'error',
              text: 'Ocurrió un error al modificar el video'
            })
          }
        }
      } else {
        this.$notify({
          type: 'error',
          text: 'Debe seleccionar una imagen para la previsualizacion del video'
        })
      }
    },
    handleThumbnailUpload (e) {
      console.log(e)
      this.postData.thumbnailFile = e.target.files[0]
      console.log(e.target.files[0])
      this.previewThumbnail(e)
      // console.log(this.image)
    },
    previewThumbnail (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          // img.onload = () => {
          //   this.image.width = img.width
          //   this.image.height = img.height
          // }
          img.src = e.target.result
          this.thumbnail.image = e.target.result
          console.log(this.thumbnail.image)
        }
      }
    },
    businessName () {
      console.log(this.businessList)
      console.log(this.postData.zbBarId)
      var business = this.businessList.find(({ zbBarId }) => zbBarId === this.postData.zbBarId)
      console.log(business.name)
      this.postData.businessTitle = business.name
    }
  }
}
