import { ServiceFactory } from '@/services/ServiceFactory'
import ZbVideoModal from './video-modal'
import draggable from 'vuedraggable'
const _appControlService = ServiceFactory.get('AppControlService')
const _barsService = ServiceFactory.get('BarsService')

export default {
  name: 'ZbPromotionalVideoComponent',
  components: {
    draggable,
    ZbVideoModal
  },
  data () {
    return {
      // premium: true,
      // zbBarId: '',
      currentItem: null,
      submitAction: 'create', // String 'create' || 'edit'
      dialog: false,
      // videosData: { selectOrder: [0] },
      // videos: [],
      // barData: {},
      // loading: false,
      category: '',
      videoOrder: [],
      list: [],
      categories: ['Nuevos videos', 'Videos populares'],
      items: [],
      loading: false,
      drag: false,
      companies: [],
      compKey: 0
    }
  },
  mounted () {
    this.initialize()
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    async initialize () {
      this.loading = true
      try {
        const controlResponse = await _appControlService.index()
        console.log(controlResponse)
        this.items = controlResponse.videos
        // this.loading = false
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.loading = false
      }
      // this.loading = true
      try {
        const resp = await _barsService.listBar()
        this.companies = resp.bars
        console.log(this.companies)
        // this.loading = false
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.loading = false
      }
      this.loading = false
    },
    async dragEnd (category) {
      this.drag = false
      console.log(category)
      this.loading = true
      var ids = []
      this.items.forEach(el => {
        if (el.category === category) {
          console.log(el.videos)
          el.videos.forEach(item => {
            ids.push(item.id)
          })
        }
      })
      ids.reverse()
      console.log(ids)
      var payload = {
        category: category,
        order: ids
      }
      try {
        const controlResponse = await _appControlService.order(payload)
        console.log(controlResponse)
        this.items = controlResponse.videos
        // this.compKey += 1
        // this.initialize()
        this.loading = false
        // this.$notify({
        //   type: 'success',
        //   text: 'Orden modificado correctamente'
        // })
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.initialize()
        this.loading = false
      }
    },
    // dragEnd () {
    //   console.log(this.categories)
    // }
    // async changePremium () {
    //   this.loading = true
    //   var premiumState = ''
    //   this.premium ? premiumState = 'Desabilitar' : premiumState = 'Habilitar'
    //   this.$swal({
    //     title: '¿' + premiumState + ' Premium en este negocio?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sí',
    //     cancelButtonText: 'Cancelar'
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       try {
    //         console.log(this.premium)
    //         var resp = await _barsService.premiumState(this.zbBarId, { isPremium: !this.premium })
    //         console.log(resp)
    //         if (this.premium) {
    //           this.$notify({
    //             type: 'success',
    //             text: 'El negocio cuenta con función Premium'
    //           })
    //         } else {
    //           this.$notify({
    //             type: 'success',
    //             text: 'El negocio ya no cuenta con función Premium'
    //           })
    //         }
    //         this.loading = false
    //         this.initialize()
    //       } catch (error) {
    //         console.log(error)
    //         this.$notify({
    //           type: 'error',
    //           text: `Ocurrió un error modificar la cuenta`
    //         })
    //         this.loading = false
    //         this.initialize()
    //       }
    //       console.log('premium change')
    //     }
    //   })
    // },
    async deleteVideo (item) {
      this.$swal({
        title: '¿Desea eliminar este video?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(item)
            var resp = await _appControlService.destroy(item.id)
            console.log(resp)
            this.$notify({
              type: 'success',
              text: 'Video eliminado correctamente'
            })
            // this.initialize()
            this.dragEnd(item.category)
          } catch (error) {
            console.log(error)
            this.$notify({
              type: 'error',
              text: `Ocurrió un error al eliminar el video.`
            })
            this.initialize()
          }
        }
      })
    },
    async videoState (item) {
      var state = item.active
      var stateText = ''
      !state ? stateText = 'habilitar' : stateText = 'deshabilitar'
      this.$swal({
        title: '¿Desea ' + stateText + ' este video?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(item)
            var resp = await _appControlService.state(item.id, { active: !state })
            console.log(resp)
            this.$notify({
              type: 'success',
              text: 'Video modificado correctamente'
            })
            this.initialize()
          } catch (error) {
            console.log(error)
            this.$notify({
              type: 'error',
              text: `Ocurrió un error al modificar el video.`
            })
            this.initialize()
          }
        }
      })
    },
    newVideoModal (category = '', videoOrder) {
      console.log('new video')
      this.dialog = true
      this.submitAction = 'create'
      this.category = category
      this.videoOrder = videoOrder
    },
    editVideo (item, category = '', videoOrder) {
      console.log(item)
      this.dialog = true
      this.submitAction = 'edit'
      this.videoOrder = videoOrder
      this.category = category
      // // this.videosData.selectOrder.push(item.order)
      this.currentItem = item
    },
    closeImgModal () {
      console.log('action on close modal')
      this.dialog = false
      this.initialize()
    }
    // async move (m, i, item, order) {
    //   // console.log(m, i, item)
    //   // this.carouselImg.splice(i, 1)
    //   var newOrder = null
    //   if (m === 'l') {
    //     console.log(i, order, item)
    //     newOrder = order - 1
    //     // console.log('new order', newOrder, order)
    //     // this.carouselImg.splice(i - 1, 0, item)
    //   }
    //   if (m === 'r') {
    //     console.log(i, order, item)
    //     newOrder = order + 1
    //     // console.log('new order', newOrder, order)
    //     // this.carouselImg.splice(i + 1, 0, item)
    //   }
    //   console.log('new order:' + newOrder, 'current: ' + order, 'id:' + item.id)
    //   try {
    //     var resp = await _videosService.edit(this.zbBarId, item.id, { order: newOrder })
    //     console.log(resp)
    //     this.$notify({
    //       type: 'success',
    //       text: 'Orden de video cambiado correctamente'
    //     })
    //     this.initialize()
    //   } catch (error) {
    //     console.log(error)
    //     this.$notify({
    //       type: 'error',
    //       text: `Ocurrió un error al cambiar el orden.`
    //     })
    //     this.initialize()
    //   }
    // }
  }
}
