var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card-title',[(_vm.action === 'create')?_c('span',{staticClass:"text-h5 text-center"},[_vm._v("Agregar Video")]):_c('span',{staticClass:"text-h5 text-center"},[_vm._v("Video")])]),_c('v-card-text',[_c('div'),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"my-0 pb-0",attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"VideoUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Link de video","required":"","outlined":"","dense":"","placeholder":"Link de video"},on:{"change":_vm.clearThumbnail},model:{value:(_vm.postData.tiktokVideoUrl),callback:function ($$v) {_vm.$set(_vm.postData, "tiktokVideoUrl", $$v)},expression:"postData.tiktokVideoUrl"}})]}}],null,true)})],1)],1),(_vm.action === 'edit')?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-caption",staticStyle:{"border-bottom":"2px solid #ff5a5f"}},[_vm._v(" Titulo ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.itemData.title)+" ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-caption",staticStyle:{"border-bottom":"2px solid #ff5a5f"}},[_vm._v(" Autor ")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.itemData.author_name)+" ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-caption",staticStyle:{"border-bottom":"2px solid #ff5a5f"}},[_vm._v(" Fecha ")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.itemData.created_at)+" ")])])],1):_vm._e(),_c('v-row',[(_vm.action === 'edit')?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h6 my-0"},[_vm._v("Posición actual: "+_vm._s(_vm.itemData.order))])]):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":_vm.action === 'create'?12:6}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories,"label":"Seleccionar categoria","required":"","dense":"","outlined":"","placeholder":"Seleccionar categoria"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Business","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.businessList,"label":"Seleccionar negocio","item-text":"name","item-value":"zbBarId","required":"","dense":"","outlined":"","placeholder":"Seleccionar negocio"},on:{"change":_vm.businessName},model:{value:(_vm.postData.zbBarId),callback:function ($$v) {_vm.$set(_vm.postData, "zbBarId", $$v)},expression:"postData.zbBarId"}})]}}],null,true)})],1)],1),(_vm.action === 'edit')?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"QViews","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de vistas","required":"","outlined":"","prepend-icon":"fas fa-eye","dense":"","placeholder":"Número de vistas"},model:{value:(_vm.postData.qViews),callback:function ($$v) {_vm.$set(_vm.postData, "qViews", $$v)},expression:"postData.qViews"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"QLikes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de likes","required":"","outlined":"","prepend-icon":"fas fa-heart","dense":"","placeholder":"Número de likes"},model:{value:(_vm.postData.qLikes),callback:function ($$v) {_vm.$set(_vm.postData, "qLikes", $$v)},expression:"postData.qLikes"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"QComments","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de comentarios","required":"","outlined":"","prepend-icon":"fas fa-comments","dense":"","placeholder":"Número de comentarios"},model:{value:(_vm.postData.qComments),callback:function ($$v) {_vm.$set(_vm.postData, "qComments", $$v)},expression:"postData.qComments"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"QShares","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de veces compartido","required":"","outlined":"","prepend-icon":"fas fa-share-alt","dense":"","placeholder":"Número de veces compartido"},model:{value:(_vm.postData.qShares),callback:function ($$v) {_vm.$set(_vm.postData, "qShares", $$v)},expression:"postData.qShares"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"flex flex-column"},[_c('div',{staticClass:"text-caption"},[_vm._v("* Puede descargar la imagen de previsualización desde estas páginas web:")]),_c('a',{staticClass:"text-caption",attrs:{"href":"https://www.svtiktok.com/","target":"_blank"}},[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("fas fa-link")]),_vm._v(" svtiktok.com")],1)]),_c('v-card',{staticClass:"scrollbar mx-auto",attrs:{"max-width":"260"}},[_c('v-card-text',{staticClass:"scrollbar"},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.thumbnail.image,"id":"bannerImg","aspect-ratio":9/16}},[_c('div',{staticClass:"banner-button"},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("fas fa-camera")])],1),_c('input',{ref:"file",staticClass:"input-banner",attrs:{"id":"thumbFile","accept":"image/*","type":"file"},on:{"change":_vm.handleThumbnailUpload}})],1)])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('div',[_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }